import { graphqlClient } from '@/utils/graphql';
import marketsQuery from '@/graphql/query/Markets.gql';
import axios from 'axios';

export async function getMarkestInfo() {
  const marketsSubscribe = await graphqlClient.subscribe({
    query: marketsQuery.findManyMarkets,
    variables: {
      orderBy: [
        {
          id: 'asc',
        },
      ],
    },
  });

  return new Promise((resolve, reject) => {
    marketsSubscribe.subscribe({
      next(response) {
        const { data: { findManyMarkets } } = response;
        const markets = findManyMarkets;
        resolve({ markets });
      },
      error(error) {
        reject(error);
      },
    });
  });
}

export async function findOneMarket(findMarket = 'CRBTC') {
  const marketsSubscribe = await graphqlClient.subscribe({
    query: marketsQuery.findFirstMarkets,
    variables: {
      where: {
        name: {
          equals: findMarket,
        },
      },
    },
  });

  return new Promise((resolve, reject) => {
    marketsSubscribe.subscribe({
      next(response) {
        const { data: { findFirstMarkets } } = response;
        const market = findFirstMarkets;
        resolve({ market });
      },
      error(error) {
        reject(error);
      },
    });
  });
}

export async function getHistoricRates(marketName) {
  try {
    const API_KEY = process.env.VUE_APP_RATES_API_KEY;
    const apiResponse = await axios.get('https://ab3lmd1w60.execute-api.us-east-2.amazonaws.com/Dev', {
      headers: {
        'X-Api-Key': API_KEY
      }
    });
    const data = JSON.parse(apiResponse.data.body);
    const marketData = data.find((market) => market.market_name.includes(marketName));
    const formattedRates = marketData.rates.map((rate) => ({
      timestamp: rate.block_timestamp,
      borrowRate: rate.borrow_rate * 100,
      depositRate: rate.deposit_rate * 100,
    }));
    return formattedRates;
  } catch (error) {
    console.error('Error fetching API:', error);
    throw error;
  }
}
